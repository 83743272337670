/**
 * common.js
 */



(function (window, doc) {

    /**
     * @typedef {object} T_DSSX
     * @property {object} APP_CONFIG - 后端返回的配置
     * @property {object} uinfo - 当前登录用户的信息 （ps: 明确要读当前登录的用户的信息时才使用这个，否则请使用currUser）
     * @property {object} stuInfo - 预览模式时，查看的学生信息，否则为空
     * @property {object} currUser - 当前实训平台使用的用户的信息（预览模式时，为查看的学生的信息），页面显示的状态（用户昵称之类）请读这个字段
     * @property {object} userPermission - 当前登录用户的权限
     * @property {object} host - 相关域名
     * @property {object} util - 帮助函数集合, 定义在 common/util.js
     * @property {object} attr - 属性(不推荐再拓展新的字段)，目前用在控制国际站状态及相关信息
     * @property {object} loadHandler - 页面 onolad （不推荐使用）
     * @property {Array} previewBlackList
     * @property {object} noLoginPage
     * @property {object} untilFn - （不推荐使用） TODO: REMOVE
     * @property {object} appProp - 应用属性, font-end application properties
     * @property {TrainingMission} TrainingMission - TrainingMission, 实训任务
     */

    /**
     * 电商实训平台的全局变量
     * @type {T_DSSX}
     */
    var DSSX = window.DSSX = window.DSSX || {};
    var util = DSSX.util;
    DSSX.loadHandler = null;

    // 应用错误
    // 若 AppError.fatalError 为 true，将中止应用
    var AppError = {
        fatalError: false,
        fatalErrorSet: {
            LoadAppConfig: false,
            LoadSso: false,
            MissingSsoUserInfo: false,
            MissingAppUserInfo: false,
        },
    };


    init();

    function init() {
        setApiBlackList();
        setNoLoginPageList();
        setDssxConfig();
        initLoadHandler();
        appendUInfo();
        initUntilFn();

        DSSX.loadHandler.New().onload = onload;
    }

    /**
     * 预览模式下接口黑名单
     * 针对post接口
     * 预览模式下拦截器会将该请求的post body内容置空
     */
    function setApiBlackList() {
        DSSX.previewBlackList = [
            '/usr/api/updateDecoTmpl',//旺铺装修模板
            '/usr/api/updateCpnyInfo',//公司信息
            '/usr/api/updateProduct',//更新产品
            '/usr/api/addProduct',//添加产品
            '/usr/api/addCreditOrder',//添加信保订单
            '/usr/api/updateOrder',//修改信保订单
            '/usr/api/addQuotation',//增加报价单
        ];
    }

    function setNoLoginPageList() {
        DSSX.noLoginPage = {
            '/ali-index.html': 1, //前台首页
        };
    }

    function setDssxConfig() {
        DSSX.APP_CONFIG = window.DSSX_CONFIG;
        window.DSSX_CONFIG = undefined; // remove DSSX_CONFIG

        if (!DSSX.APP_CONFIG) {
            console.error('[common] cannot not found APP config');
            AppError.fatalError = true;
            AppError.fatalErrorSet.LoadAppConfig = true;
            return;
        }

        var host = DSSX.APP_CONFIG.host,
            map = host.srvList || {}
        ;

        var o = {
            main: map.dssx,
            main_en: map.dssx_en,
            course: map.course,
            kuxiao: map.kuxiao,
            fs: map.fs,
            sso: map.sso,
            protocol: host.protocol || '',
            previewModHostDomain: host.previewModeDomain || '', // 'dev.gdy.io',  预览模式的域名 - dsalbbpxxx.dev.gdy.io
        };

        DSSX.host = DSSX.util.extend({}, DSSX.host, o);
    }

    function appendUInfo() {
        if (AppError.fatalError) {
            return;
        }

        var dssxUInfoUrl = '/pub/api/dssx_uinfo.js?selector=ALL';

        var host = DSSX.host;
        // 正常逻辑
        var ssouinfoUrl = (host.protocol || '') + '//' + host.sso + '/sso/api/uinfo.js?user=1&selector=basic';
        // 本地开发使用，注意不要提交
        // var ssouinfoUrl = (host.protocol || '') + '//' + host.sso + '/sso/api/uinfo.js?user=1&selector=basic&token=' + util.queryString('token');
        doc.write('<script id="sso_uinfo" src="' + ssouinfoUrl + '"></script>');

        var uinfo = doc.querySelector('#sso_uinfo');
        uinfo.onload = function () {
            var timestamp = Date.now();
            var url = dssxUInfoUrl + '&timestamp=' + timestamp + '&token=' + (UINFO.token || '');
            doc.write('<script src="' + url + '"></script>');
        };
        uinfo.onerror = function () {
            AppError.fatalError = true;
            AppError.fatalErrorSet.LoadSso = true;
            console.error('[common] load sso uinfo fail');
        }
    }

    function initLoadHandler() {
        /*
        * 请不要依赖这个，将考虑去掉
        * */
        var LoadHandler = function () {
            var evts = [];
            this.New = function () {
                var t = {};
                evts.push(t);
                return t;
            };
            this.load = function () {
                for (var i = 0; i < evts.length; i++) {
                    if (evts[i].onload) {
                        evts[i].onload.apply(window);
                    }
                }
            }
        };
        window.DSSX.loadHandler = new LoadHandler();
        doc.addEventListener('DOMContentLoaded', function () {
            DSSX.loadHandler.load();
        });
    }

    function initUntilFn() {
        DSSX.untilFn = {};

        // 检查登录状态和权限状态
        DSSX.untilFn.check = function (info, token, loginUrl) {
            if (AppError.fatalError) {
                return false;
            }

            info = info || {};

            // 未登录
            if (!info.uid || !token) {
                DSSX.untilFn.jumpLoginPage(loginUrl);
                return false;
            }

            // 实训角色为空，为受限用户，不能使用实训平台
            if (!info.trainingRole) {

                // 管理员，跳至管理页面
                if (info.role && info.role.indexOf('RCP_ADMIN') !== -1) {
                    jumpManagePage();
                } else {
                    console.log('[common.js] 受限用户不能访问');

                    // 其它，跳至受限页
                    DSSX.untilFn.jumpBuyGuidancePage();
                    return false;
                }
            }

            return true;
        };

        DSSX.untilFn.jumpLoginPage = function (loginUrl) {
            if (!DSSX.util.pageRequireLogin()) {
                return;
            }

            var currUrl = DSSX.util.delUrlSearchParam(window.location.href, 'token');
            currUrl = encodeURIComponent(currUrl);

            if(loginUrl) {
                var regex = new RegExp("(url=)([^&]+)");
                var newUrl = loginUrl.replace(regex, "$1" + currUrl);
                DSSX.util.jumpPage(newUrl);
                return;
            }

            DSSX.util.jumpPage(DSSX.host.protocol + '//' + DSSX.host.sso + '/sso/index.html?url=' + currUrl);
        };

        DSSX.untilFn.jumpBuyGuidancePage = function () {
            var pagePathName = '/buy-guidance.html';
            if (pagePathName === location.pathname) {
                return;
            }
            DSSX.util.jumpPage(pagePathName);
        };

        function jumpManagePage() {
            var pagePathName = '/manage.html';
            if (pagePathName === location.pathname) {
                return;
            }
            DSSX.util.jumpPage(pagePathName);
        }
    }

    function uinfoOnSuccess() {
        appFatalErrorHandler();
        var DSSX_UINFO = window._G_DSSX_UINFO || {},
            UINFO = window.UINFO || {},
            SRV_TIME = window.SRV_TIME,
            ONLOAD_TIME = window.ONLOAD_TIME,
            SSO_LOGIN_URL = window.SSO_LOGIN_URL,
            APP_CONFIG = window.DSSX.APP_CONFIG || {},
            APP_CONFIG_MODULE_CONF = APP_CONFIG.dev || {}
        ;

        window.UINFO = undefined;
        window.SRV_TIME = undefined;
        window.ONLOAD_TIME = undefined;
        window.SSO_LOGIN_URL = undefined;

        var DUINFO = DSSX_UINFO.DUINFO || {},
            PINFO = DSSX_UINFO.PINFO || {},
            dssxHost = DSSX.host,

            logonUser = DSSX.util.extend({}, DUINFO),
            attr = {
                isPreviewMode: false,
                state: '', // 国际站开通状态
                previewPrefix: '', // 预览模式的前缀
                previewModeHost: '', // 预览模式的域名
            },
            appProp = {
                token: UINFO.token || '',
                srvTime: SRV_TIME,
                onLoadTime: ONLOAD_TIME,
                logined: false,
                sysMode: APP_CONFIG.sysMode || 'teach',

                // 'default' or 'xkz'
                examType: APP_CONFIG.examType || 'default',

                // 功能模块 配置
                moduleConf: {
                    // 禁用预览模式入口
                    disablePreviewModeEntry: !!APP_CONFIG_MODULE_CONF.disablePreview,
                },
            }
        ;
        if (!DSSX.untilFn.check(logonUser, appProp.token, SSO_LOGIN_URL)) {
            console.log('[common]: Not logged in');
        } else {
            appProp.logined = true;
        }

        // 是否是预览模式
        var isPreviewMode = attr.isPreviewMode = !!DSSX_UINFO.isPreviewMod;


        var targetUser;
        if (!isPreviewMode) {
            targetUser = logonUser;
        } else {
            var usr = PINFO.stu;
            if (!(usr && usr.id)) {
                console.error('preview mod: cannot found student info')
                usr = {};
            }

            targetUser = {
                isShowAccountOpenTip: false,  // 预览模式，不进行国际站状态提示
                uid: usr.id,
                usr: usr,
                tag: DSSX.util.safe(usr, 'attrs.basic.tag', ''), // 预览对象的 tag 在 stu.attrs.basic.tag
            }
        }

        // 国际站开通状态
        attr.state = DSSX.util.safe(targetUser, 'usr.attrs.basic.state', 'write_auth_info');

        // 预览模式前缀
        var previewPrefix = targetUser.tag || '';

        // 当前用户的预览模式前缀
        attr.previewPrefix = previewPrefix;
        // 当前用户的预览模式的域名
        attr.previewModeHost = previewPrefix + '.' + dssxHost.previewModHostDomain;

        DSSX.attr = attr;
        DSSX.uinfo = logonUser;
        DSSX.currUser = targetUser;
        DSSX.userPermission = getRolePermissions(logonUser);
        isPreviewMode && (DSSX.stuInfo = targetUser);

        DSSX.appProp = appProp;
    }

    // 应用发生致命错误时进行处理；提示错误信息
    function appFatalErrorHandler() {
        if (!window._G_DSSX_UINFO || !window.UINFO) {
            console.error('[common] Lack of necessary data');
            AppError.fatalError = true;
            AppError.fatalErrorSet.MissingAppUserInfo = !window._G_DSSX_UINFO;
            AppError.fatalErrorSet.MissingSsoUserInfo = !window.UINFO;
        }

        if (!AppError.fatalError) {
            return;
        }

        var msg = '[common] 发生致命错误，可能为网络问题、代码错误、或服务错误，请稍后重试';
        msg += '<br/>' + JSON.stringify({Error: AppError.fatalErrorSet, localTimeStamp: Date.now()});

        console.error(msg);
        if (window.layer && layer.alert) {
            layer.alert(msg);
        } else {
            window.alert(msg);
        }
    }

    function onload() {
        uinfoOnSuccess();

        if (DSSX.appProp.logined) {
        }
    }

    // 用户权限判断
    function getRolePermissions(logonUser) {
        var role = logonUser.role || [];
        var permission = {
            P4P_TASK_COUNT: false,
            COURSE_CONFIG: false,
            SYSTEM_TASK_COUNT: false
        };

        if (role.indexOf('RCP_ADMIN') !== -1) {
            permission.P4P_TASK_COUNT = true;
            permission.COURSE_CONFIG = true;
            permission.SYSTEM_TASK_COUNT = true;
            permission.isAdmin = true;
        }

        if (logonUser.isTeacher) {
            permission.P4P_TASK_COUNT = true;
        }

        return permission;
    }

})(window, document);
