;(function (window) {
    var DSSX = window.DSSX,
        _store = {
            isDebug: false,
            mockSrvTime: NaN, // mock 当前时间，默认值要为 NaN
        };

    if (!DSSX) {
        DSSX = window.DSSX = {};
    }


    initialization();

    function initialization() {
        _store.isDebug = !!queryString('ds_debug');

        _store.isDebug && initializeDebugInfo();

        DSSX.util = {
            getToken: getToken,
            getCookie: getCookie,
            safe: safe,
            sget: sget,
            queryString: queryString,
            //以模块的名字 注册回调函数在上面
            onSettingLayerCallBack: {},
            throttle: throttle,
            debounce: debounce,
            setTempStorage: setTempStorage,
            getTempStorage: getTempStorage,
            handle301: handle301,
            extend: extend,
            updateUrlSearch: updateUrlSearch,
            delUrlSearchParam: delUrlSearchParam,
            isInBlackList: isInBlackList,
            pageRequireLogin: pageRequireLogin,
            getServerCurrentTime: getServerCurrentTime,
            compileTemplateLiterals: compileTemplateLiterals,
            formatDate: formatDate,
            inherits: inherits,
            jumpPage: jumpPage,
            decodeHtml: decodeHtml,
            minError: minError,
            getNeedCountClickPageUrl: getNeedCountClickPageUrl,
            fileSizeToStr: fileSizeToStr
        };
    }

    function initializeDebugInfo() {
        // init mock server time
        var mockSrvTime = queryString('ds_srv_time');
        if (mockSrvTime) {
            if (!isNaN(+mockSrvTime)) {
                // 如果是数字的字符串，转换成 Number
                mockSrvTime = +mockSrvTime;
            }
            _store.mockSrvTime = new Date(mockSrvTime).getTime();
        }
    }


    function getToken() {
        var token = DSSX.appProp && (token = DSSX.appProp.token);
        return token || queryString('token') || getCookie('token');
    }

    function getCookie(key) {
        var aCookie = document.cookie.split('; ');
        for (var i = 0; i < aCookie.length; i += 1) {
            var aCrumb = aCookie[i].split('=');
            if (key === aCrumb[0]) {
                return unescape(aCrumb[1] || '');
            }
        }
        return '';
    }

    function safe(argData, argCheck, argValue) {
        var temKey = argCheck.toString().split('.'),
            temLen = temKey.length;
        // if ((typeof argValue) === 'undefined') {
        //     argValue = false;
        // }
        if (!argData) {
            return argValue;
        }
        if (temLen > 1) {
            for (var i = 0; i < temLen - 1; i++) {
                if (typeof argData[temKey[i]] !== 'object') {
                    return argValue;
                }
                argData = argData[temKey[i]] || {};
            }
        }
        return argData[temKey[temLen - 1]] || argValue;
    }

    // sget 比 safe 优化了返回默认值问题
    //  结果值为 undefined 或 null 返回默认值
    //  结果值为 0, "", false, NaN 这类 falsy 值会被返回
    function sget(obj, path, defaultValue) {
        var keyList = path.toString().split('.'),
          keyLength = keyList.length;

        var result = obj;
        if (keyLength > 0) {
            for (var i = 0; i < keyLength; i++) {
                if (result == null) {
                    break;
                }
                result = result[keyList[i]];
            }
        }

        return result == null ? defaultValue : result
    }

    /**
     * queryString 获取URL参数
     * @param  {string} name - 参数名
     * @return {string}
     */
    function queryString(name) {
        var result = window.location.search.match(new RegExp('[\?\&]' + name + '=([^\&]+)', 'i'));
        if (!result) {
            return '';
        }
        return decodeURIComponent(result[1]);
    }

    /**
     * 函数节流
     * @param func 原本执行函数
     * @param wait 间隔毫秒
     * @returns {Function} 包裹函数
     */
    function throttle(func, wait) {
        var context, args, timeout, result;
        var previous = 0;
        var later = function () {
            previous = new Date;
            timeout = null;
            result = func.apply(context, args);
        };
        return function () {
            var now = new Date;
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0) {
                clearTimeout(timeout);
                timeout = null;
                previous = now;
                result = func.apply(context, args);
            } else if (!timeout) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    }

    function debounce(func, wait, immediate) {
        var timeout, args, context, timestamp, result;

        var later = function () {
            var last = Date.now() - timestamp;

            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout) context = args = null;
                }
            }
        };

        return function () {
            context = this;
            args = arguments;
            timestamp = Date.now();
            var callNow = immediate && !timeout;
            if (!timeout) timeout = setTimeout(later, wait);
            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }

            return result;
        };
    }

    /**
     *  本地存储
     * @param {*} contentTemp 需要存储的内容
     * @param {string} [sessionKey]  自定义存入的key，当为空的时候将生成一个
     * @returns {string}
     */
    function setTempStorage(contentTemp, sessionKey) {
        var keyTemp = sessionKey;
        if (typeof keyTemp !== 'string') {
            keyTemp = new Date().getTime() + '';
            keyTemp += Math.random().toFixed(4).slice(2) + '';
        }

        var fail = true;
        var tryAgain = false;
        while (fail || tryAgain) {
            try {
                window.sessionStorage.setItem(keyTemp, JSON.stringify(contentTemp));
                fail = false;
            } catch (e) {
                console.error("sessionStorage error:", e);
                window.sessionStorage.clear();
                tryAgain = fail;
                fail = false;
            }
        }
        return keyTemp;
    }

    /**
     * 读取本地存储
     * @param keyTemp 对应的key
     */
    function getTempStorage(keyTemp) {
        var contentTemp = window.sessionStorage.getItem(keyTemp);
        if (!contentTemp) {
            return '';
        }
        var parseObj = '';
        try {
            parseObj = JSON.parse(contentTemp);
        } catch (e) {
            console.error(e);
        }
        return parseObj;
    }


    /**
     * handle301
     */
    function handle301(reqRedirectLink) {
        // if (!DSSX.util.pageRequireLogin()) {
        //     return;
        // }
        if(reqRedirectLink) {
            return jumpPage(reqRedirectLink);
        }

        var ssoPath = DSSX.host.protocol + '//' + DSSX.host.sso + '/sso/';
        var curLink = delUrlSearchParam(window.location.href, 'token'); // http://dssxs.dyfchk2.kuxiao.cn/usr/api/quotePage?rfqId=594f40fee3051933997db7a1&"
        var redirectLink = ssoPath + "?url=" + encodeURIComponent(curLink);
        return jumpPage(redirectLink);
    }

    // ------------------------------------------------
    function extend(arg_dst) {
        for (var i = 1, ii = arguments.length; i < ii; i++) {
            var obj = arguments[i];
            if (obj) {
                var keys = Object.keys(obj);
                for (var j = 0, jj = keys.length; j < jj; j++) {
                    var key = keys[j];
                    arg_dst[key] = obj[key];
                }

            }

        }
        return arg_dst;
    }

    /**
     * updateUrlSearch
     * @param {string} arg_url
     * @param {string} arg_key
     * @param {string} [arg_value]
     * @return {string}
     */
    function updateUrlSearch(arg_url, arg_key, arg_value) {
        if (typeof arg_url !== 'string') {
            return '';
        }
        arg_url = arg_url.trim();
        if (!arg_key) {
            return arg_url;
        }

        arg_key = encodeURIComponent(arg_key);
        arg_value = encodeURIComponent(arg_value);

        var search;
        var left = arg_url.indexOf('?');
        var right = arg_url.indexOf('#');

        if (right === -1) {
            right = arg_url.length;
        }
        if (left === -1 || left > right) {
            arg_url = arg_url.slice(0, right) + '?' + arg_url.slice(right);
            left = right;
            right++;
        }
        search = arg_url.slice(left + 1, right);

        var params = search.length ? search.split('&') : [];
        var i = params.length;
        var kv;
        while (i--) {
            kv = params[i].split('=');
            if (kv[0] === arg_key) {
                kv[1] = arg_value;
                params[i] = kv.join('=');
                break;
            }
        }

        if (i < 0) {
            params[params.length] = arg_key + '=' + arg_value;
        }

        arg_url = arg_url.slice(0, left + 1) + params.join('&') + arg_url.slice(right);
        return arg_url;
    };

    /**
     * delUrlSearchParam
     * @param {string} arg_url
     * @param {string} arg_key
     * @return {string}
     */
    function delUrlSearchParam(arg_url, arg_key) {
        if (typeof arg_url !== 'string') {
            return '';
        }
        arg_url = arg_url.trim();

        if (!arg_key) {
            return arg_url;
        }

        var search,
            left = arg_url.indexOf('?'),
            right = arg_url.indexOf('#');

        if (right === -1) {
            right = arg_url.length;
        }
        if (left === -1 || left > right) {
            arg_url = arg_url.slice(0, right) + '?' + arg_url.slice(right);
            left = right;
            right++;
        }
        search = arg_url.slice(left + 1, right);


        if (search.length === 0) {
            return arg_url;
        }

        arg_key = encodeURIComponent(arg_key);

        var paramsStr,
            result,
            reg = new RegExp('&' + arg_key + '=[^&]+', 'ig');
        paramsStr = search[0] === '&' ? search : '&' + search; // &a=b&c=d&key=value
        result = paramsStr.replace(reg, '');
        result && (result = result.slice(1)); // a=b&c=d

        arg_url = arg_url.slice(0, left + 1) + result + arg_url.slice(right);
        return arg_url;
    }

    /**
     *
     * @param {string} arg_url
     * @returns {boolean}
     */
    function isInBlackList(arg_url) {
        if (typeof arg_url !== 'string' || !arg_url) {
            return false;
        }

        if (!DSSX.attr.isPreviewMode) {
            return false;
        }

        for (var i = 0; i < DSSX.previewBlackList.length; i++) {
            if (arg_url.indexOf(DSSX.previewBlackList[i]) > -1) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @returns {boolean}
     */
    function pageRequireLogin() {
        return !(location.pathname in DSSX.noLoginPage);
    }

    /**
     * 获取服务器现在的时间
     * @return {Date}
     */
    function getServerCurrentTime() {
        var appProp = DSSX.appProp || {}
            , s = isNaN(_store.mockSrvTime) ? appProp.srvTime : _store.mockSrvTime
            , onload;
        (undefined === s || null === s) && (s = Date.now());
        onload = appProp.onLoadTime == null ? s : appProp.onLoadTime;
        return new Date(new Date().getTime() - onload + s);
    }

    /**
     * 编译模板字符串 模拟
     * @param tpl 模板
     * @param object 作用域
     * @returns {string}
     */
    function compileTemplateLiterals(tpl, object) {
        if (typeof tpl !== 'string') {
            return '';
        }
        object = object || {};
        return tpl.replace(/\${([^${}]+)}/g, function (match, key) {
            return object[key];
        });
    }

    /**
     * formatDate
     * @param {Date|String|Number} time
     * @param {String} format - eg: 'yyyy-MM-dd hh:mm', 'yyyy-MM-dd'
     * @return {*}
     */
    function formatDate(time, format) {
        var date = new Date(time);
        var o = {
            "M+": date.getMonth() + 1, //月
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季
            "S": date.getMilliseconds() //毫秒
        };

        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }

        for (var k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
            }
        }
        return format;
    }

    /**
     * 继承
     * @param subclass
     * @param superclass
     */
    function inherits(subclass, superclass) {
        for (var k in superclass) {
            if (superclass.hasOwnProperty(k)) {
                subclass[k] = superclass[k];
            }
        }

        function __Fun() {
            this.constructor = subclass;
        }

        __Fun.prototype = superclass.prototype;
        subclass.prototype = new __Fun();
    }

    /**
     * jumpPage
     * @param {string} url
     */
    function jumpPage(url) {
        if (_store.isDebug) {
            console.info('[common.util] jump page cancelled', url);
        } else {
            console.info('[common.util] jump page to:', url);
            window.location.href = url;
        }
    }

    /**
     * [decodeHtml 反转义html标签]
     * @param  {[type]} argHtml [description]
     * @return {[type]}         [description]
     */
    function decodeHtml(argHtml) {
        if (!argHtml || argHtml.length === 0) {
            return '';
        }
        argHtml = argHtml.replace(/&amp;/g, '&');
        argHtml = argHtml.replace(/&lt;/g, '<');
        argHtml = argHtml.replace(/&gt;/g, '>');
        argHtml = argHtml.replace(/&nbsp;/g, ' ');
        argHtml = argHtml.replace(/&#39;/g, '\'');
        argHtml = argHtml.replace(/&quot;/g, '\"');
        argHtml = argHtml.replace(/&times;/g, '*');
        // argHtml = argHtml.replace(/<br>/g, '\n');
        return argHtml;
    }

    /**
     * @description
     *
     * 对 `angular.$$minErr` 功能的模仿
     * 不同在于模板功能由 util.compileTemplateLiterals 提供，因此模板语法不一样
     * @param {string} module
     * @param {function=} ErrorConstructor
     */

    function minError(module, ErrorConstructor) {
        ErrorConstructor = ErrorConstructor || Error;

        return function (code, template, templateArgsContext) {
            var message = '[' + (module ? module + ':' : '') + code + '] ';
            message += compileTemplateLiterals(template, templateArgsContext);

            return new ErrorConstructor(message);
        }
    }

    /**
     * getNeedCountClickPageUrl
     * @param targetId {string} 产品id
     * @param hid {string} hid
     * @param source {string} 来源
     * @param urlType {string} 跳转目标 prod: 产品详情, shop: 旺铺
     * @param options {object} 可选参数
     * @returns {string}
     */
    function getNeedCountClickPageUrl(targetId, hid, source, urlType, options) {

        var baseUrl,
            targetUrl,
            sign,
            urlTypes = ['prod', 'shop'],
            uid = DSSX.currUser.uid
        ;

        if (!(targetId && hid && source)) {
            console.warn('[getProductDetailUrl_fn params error]: [targetId:%s], [hid:%s]， [source:%s] ', targetId, hid, source);
            return '';
        }

        if (urlTypes.indexOf(urlType) === -1) {
            console.warn('[getProductDetailUrl_fn params error]: [urlType]: ', urlType);
            return '';
        }

        if (!uid) {
            console.warn('[getProductDetailUrl_fn DSSX.currUser.uid is not found]');
            return '';
        }

        sign = md5(uid + '' + targetId + '' + hid);

        baseUrl = urlType === 'prod' ? '/pub/api/hitPrd?pid=' : '/pub/api/hitPopStore?sid=';

        targetUrl = baseUrl + targetId + '&source=' + source + '&hid=' + hid + '&sign=' + sign + '&token=' + getToken();

        if (options && typeof options === 'object') {
            for (var attr in options) {
                if (options.hasOwnProperty(attr)) {
                    targetUrl += '&' + attr + '=' + options[attr];
                }
            }
        }

        return targetUrl;
    }

    function fileSizeToStr(size) {
        var limitStrMap = {
            k: +(size / 1024).toFixed(2),
            m: +(size / 1024 / 1024).toFixed(2),
            g: +(size / 1024 / 1024 / 1024).toFixed(2),
        };
        var limitStr = limitStrMap.g + 'G';
        if(limitStrMap.g < 1) {
            limitStr = limitStrMap.m + 'M'
        }
        if(limitStrMap.m < 1) {
            limitStr = limitStrMap.k + 'K'
        }
        if(limitStrMap.k < 1) {
            limitStr = size + 'B'
        }
        return limitStr;
    }
})(window);
