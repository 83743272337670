/**
 * 公共jq请求方法
 * 使用：DSSX.request.get/post/send
 *
 * @param arg_url 请求地址
 * @param arg_param 请求参数key-value
 * @param arg_data post body内容
 * @param arg_needLogin 是否需要登陆
 * @param arg_filter $.ajax里的option
 * @returns promise.then(successCallback(rs), failCallback(err))
 *          rs: xhrResponse.responseJSON
 *          err: {
 *              type: 1/-1/-2,（1：业务逻辑错误，-1：没有响应code，-2：请求错误）
 *              data: xhrResponse
 *          }
 *
 * err.type > 0，业务逻辑错误或业务逻辑非默认情况; type < 0, 请求错误，或接口响应数据格式不符合预期
 *      （后面定义 type 请参照该定义）
 */
!function () {
    DSSX.loadHandler.New().onload = function () {

        /**
         * HTTP GET request.
         * @callback request.get
         * @param {string} url
         * @param {Object=} param
         * @param {boolean} [needLogin=false]
         * @param {Object=} filter
         * @return {Deferred|Promise}
         */

        /**
         * HTTP POST request
         * @callback request.post
         * @param {string} url
         * @param {Object=} param
         * @param {Object|string} [data]
         * @param {boolean} [needLogin=false]
         * @param {Object=} filter
         * @return {Deferred|Promise}
         */

        /**
         * jQuery.ajax 的封装
         * @callback request.send
         * @param {string} url
         * @param {Object=} option
         * @return {Deferred|Promise}
         */

        /**
         * request
         * @type {{get: request.get, post: request.post, send: request.send}}
         */
        DSSX.request = JqRequestFactory();
    };

    /**
     * JqRequestFactory
     * @return {{get: get, post: post, send: send}}
     */
    function JqRequestFactory() {
        var request = {
            /**
             * HTTP GET request
             * @param {string} url
             * @param {Object=} param
             * @param {boolean} [needLogin=false]
             * @param {Object=} filter
             * @return {Deferred|Promise}
             */
            get: function (url, param, needLogin, filter) {
                filter = filter || {};
                delete filter['type'];
                delete filter['data'];

                url = this.buildUrl(url, {needLogin: needLogin, params: param});

                return this.send(url, $.extend(true, {
                    type: 'get',
                }, filter || {}));
            },
            /**
             * HTTP POST request
             * @param {string} url
             * @param {Object=} param
             * @param {Object|string} [data]
             * @param {boolean} [needLogin=false]
             * @param {Object=} filter
             * @return {Deferred|Promise}
             */
            post: function (url, param, data, needLogin, filter) {
                filter = filter || {};
                delete filter['type'];
                delete filter['data'];

                url = this.buildUrl(url, {needLogin: needLogin, params: param});
                data || (data = {});
                if ('string' !== typeof data) {
                    data = JSON.stringify(data);
                }

                return this.send(url, $.extend(true, {
                    type: 'post',
                    data: data,
                    dateType: 'json',
                    contentType: 'application/json',
                }, filter || {}));
            },
            /**
             * jQuery.ajax 的封装
             * @param {string} url
             * @param {Object=} option
             * @return {Deferred|Promise}
             */
            send: function (url, option) {
                option = isObject(option) ? option : {};
                delete option['success'];
                delete option['error'];

                DSSX.util.isInBlackList(url) && (option.data = {});

                return $.ajax(url, option).then(function (rs, status, xhr) {
                    xhr.data = rs;
                    return responseHandler(xhr);
                }, function (err) {
                    return $.Deferred().reject({
                        type: -2,
                        data: err
                    });
                });
            },
            getToken: function () {
                return DSSX.util.getToken();
            },
            buildUrl: function (url, option) {
                if (!option || typeof url !== 'string') {
                    return url;
                }
                var serializedParams = paramSerializer(option.params),
                    updateUrlSearch = DSSX.util.updateUrlSearch;

                if (serializedParams.length > 0) {
                    url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams;
                }
                option.needLogin && (url = updateUrlSearch(url, 'token', this.getToken()))

                return url;
            },
        };

        return request;

        function responseHandler(arg_response) {
            var deferred = $.Deferred()
            if (!arg_response || !arg_response.data || typeof arg_response.data.code === 'undefined') {
                deferred.reject({
                    type: -1,
                    data: arg_response
                });
                return deferred.promise();
            }

            var code = DSSX.util.safe(arg_response, 'data.code', 0);
            if (code === 0) {
                deferred.resolve(arg_response.data);
            } else if (code === 301) {
                DSSX.util.handle301(DSSX.util.safe(arg_response, 'data.data', ''));
                deferred.reject({
                    // type === 1301 登录信息过期
                    type: 1301,
                    data: arg_response
                });
            } else {
                deferred.reject({
                    // type === 1 请求正常 业务逻辑错误
                    type: 1,
                    data: arg_response
                });
            }

            return deferred.promise();
        }
    }

    /**
     * paramSerializer
     * 移植于 https://code.angularjs.org/1.6.8/docs/api/ng/service/$httpParamSerializer
     * @param params
     * @return {string}
     */
    function paramSerializer(params) {
        if (!params) return '';
        var parts = [];
        forEachSorted(params, function (value, key) {
            if (value === null || isUndefined(value) || isFunction(value)) return;
            if (Array.isArray(value)) {
                value.forEach(function (v) {
                    parts.push(encodeUriQuery(key) + '=' + encodeUriQuery(serializeValue(v)));
                })
            } else {
                parts.push(encodeUriQuery(key) + '=' + encodeUriQuery(serializeValue(value)));
            }
        });

        return parts.join('&');
    }

    function forEachSorted(obj, iterator, context) {
        var keys = Object.keys(obj).sort();
        for (var i = 0; i < keys.length; i++) {
            iterator.call(context, obj[keys[i]], keys[i]);
        }
        return keys;
    }

    function encodeUriQuery(val, pctEncodeSpaces) {
        return encodeURIComponent(val).replace(/%40/gi, '@').replace(/%3A/gi, ':').replace(/%24/g, '$').replace(/%2C/gi, ',').replace(/%3B/gi, ';').replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
    }

    function serializeValue(v) {
        if (isObject(v)) {
            return isDate(v) ? v.toISOString() : JSON.stringify(v);
        }
        return v;
    }

    function isObject(arg_val) {
        return arg_val !== null && typeof arg_val === 'object';
    }

    function isUndefined(value) {
        return typeof value === 'undefined';
    }

    function isFunction(value) {
        return typeof value === 'function';
    }

    function isDate(value) {
        return toString.call(value) === '[object Date]';
    }

}();