!function () {
    DSSX.loadHandler.New().onload = function () {
        DSSX.dialog = dialogHandler();
    };

    function dialogHandler() {
        // 部分 error type 显示的替换原因
        var errorTypeAlternativeReason = {
            '1301': '登录超时，请重新登录',
            '-1': '未知接口格式',
            '-2': '网络错误，请稍后再试',
            '-3': '请求超时',
        };

        //  排除的 error type
        var excludeErrorTypes = [
            -4, // 手动中断请求
        ];

        // 部分 data code 前面加入提示语
        var dataCodeInsertion = {
            301: '登录超时',
        };


        // 排除的 code
        var excludeCodes = [
            403, 413, // 预览模式不允许更改
        ];


        function formatText(arg_moduleName, arg_funcName, arg_text, arg_code, arg_reason) {
            var list = [
                arg_funcName ? ('[' + (arg_moduleName || '') + '(' + arg_funcName + ')]') :
                    (arg_moduleName ? '[' + arg_moduleName + ']' : ''),
                arg_text ? (arg_text + ', ') : '',
                '错误码：',
                arg_code,
                arg_reason ? (', 原因：' + arg_reason) : '',
            ];
            return list.join('');
        }

        return {
            msg: function (arg_msg) {
                layer.msg && layer.msg(arg_msg);
            },
            /**
             * 显示错误提示的静态方法
             * @param arg_err 'request().then(sucCallBack, errCallBack)' errCallBack的参数
             *              {type: 1, data: {data: xhrResponse}}
             * @param arg_option . {moduleName: '', funcName: '', text: ''}}
             * @returns {string} e.g. [测试模块(MyFunction)] 提示语, 错误码: 1, 原因, arg-err, param is nil
             */
            err: function (arg_err, arg_option) {
                if (!arg_err) {
                    return '';
                }
                arg_option = arg_option || {};

                // var safe = DSSX.util.safe;
                var errType, requestResponse, xhrResponse, code,
                    moduleName, funcName, text, reason, reasons = []
                ;

                // ------------ 检查 type
                /**
                 * 错误类型 errType
                 * err.type > 0，业务逻辑错误或业务逻辑非默认情况; type < 0, 请求错误，或接口响应数据格式不符合预期
                 * err.type 为字符串，前端检测不通过，未调用接口
                 * -2: 请求错误
                 * -1: 请求正常，接口没有返回code
                 * 1: 请求正常 业务逻辑错误
                 * 102: http 拦截器 response reject
                 * 1301: 登录信息过期
                 *
                 * UPLOAD_CHECK: 上传接口调用前，前端检测不通过
                 */
                errType = arg_err.type;

                if(errType === 'UPLOAD_CHECK') {
                    return this.msg(arg_err.msg);
                }

                if (excludeErrorTypes.indexOf(errType) > -1) {
                    // 部分 error type 不显示提示
                    console.log('[dialog] ignore the error because error type is', errType);
                    return '';
                }

                if (errorTypeAlternativeReason[errType]) {
                    reasons.push(errorTypeAlternativeReason[errType]);
                    reason = reasons.join(' ');
                    /* TODO: 加上 url */
                    return this.msg(reason)
                }


                // ------------ 检查 code
                requestResponse = arg_err.data || {};
                xhrResponse = requestResponse.data || {};
                code = typeof xhrResponse.code !== 'undefined' ? xhrResponse.code : '';

                if (excludeCodes.indexOf(code) > -1) {
                    // 部分 code 不显示提示
                    console.log('[dialog] ignore the error because code is', code);
                    return '';
                }

                if (code === 440) {
                    console.log('[dialog] 考试以结束');
                    DSSX.exam = DSSX.exam || {};
                    var openExamFinishTip = DSSX.exam.openExamFinishTip;

                    if (typeof openExamFinishTip === 'function') {
                        openExamFinishTip();
                    }

                    return '';
                }

                // ------------ 读取组装原因
                dataCodeInsertion[code] && reasons.push(dataCodeInsertion[code]);

                xhrResponse.msg && reasons.push(xhrResponse.msg.toString());
                xhrResponse.dmsg && reasons.push(xhrResponse.dmsg.toString());


                // ------------ 组装提示文本
                moduleName = (arg_option.moduleName || '').trim();
                funcName = (arg_option.funcName || '').trim();
                text = (arg_option.text || '').trim();

                if (!moduleName && !funcName && !text && !reasons.length) {
                    return '';
                }

                reason = reasons.join(' ');

                return this.msg(
                    formatText(moduleName, funcName, text,
                        code,
                        reason));
            }
        }
    }
}();