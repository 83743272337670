;(function (window) {
    var DSSX = window.DSSX;
    if (!DSSX) {
        DSSX = window.DSSX = {};
    }

    var SYSTEM_MODE = DSSX.APP_CONFIG.sysMode || 'teach';


    /**
     * @typedef {Object} T_MissionType
     * @property {string} TradeAssurance_Order -  信用保障-信保订单
     * @property {string} Product_Publish -  产品管理-产品发布（非直接下单）
     * @property {string} Store_V1 -  旺铺装修-v1
     * @property {string} Rfq_Offer -  采购直达-RFQ 报价
     * @property {string} Inq_Offer -  商机管理-询盘报价
     * @property {string} SiteManage_Company -  建站管理-公司信息
     * @property {string} P4P_KeywordBid -  P4P-关键词出价
     * @property {string} Store_V2 -  旺铺装修-v2
     * @property {string} SiteManage_SubAccount -  建站管理-添加子账号
     * @property {string} SiteManage_QuickEntry -  建站管理-快捷入口设置
     * @property {string} Product_Manage -  产品管理-产品管理
     * @property {string} Product_Window - 产品管理-橱窗设置
     * @property {string} ZeroEffectProduct -  产品管理-零效果产品
     * @property {string} P4P_AccountBudget -  P4P-账户预算
     * @property {string} Inq_Rubbish -  商机管理-垃圾询盘
     * @property {string} Inq_Allot -  商机管理-询盘分配
     * @property {string} Inq_CusGroup -  商机管理-客户分组
     * @property {string} Inq_CusAllot -  商机管理-客户分配
     * @property {string} Inq_AddCus -  商机管理-添加客户
     * @property {string} Inq_Rule -  商机管理-询盘分配规则
     * @property {string} Rfq_CustomSearch -  采购直达-定制搜索
     * @property {string} Open_Alibaba_Fully - 建站管理-同步考试任务
     * @property {string} MultiLangMarket_Product_Publish -  多语言市场-产品发布
     * @property {string} Inq_AddCus_From_TradeManage -  从TradeManage导入客户
     * @property {string} Product_Publish_DirectOrder -  产品管理-产品发布（可直接下单）
     * @property {string} Business_AddCategory -  商机订阅-添加类目
     * @property {string} Business_AddTag -  商机订阅-添加订阅词
     * @property {string} Inq_Send -  商机管理-发送询盘
     * @property {string} Rfq_Purchase -  采购直达-发布RFQ采购需求
     * @property {string} Order_Shipped_Offline -  交易管理-信保订单发货
     */

    /**
     * MissionType
     * @type {T_MissionType}
     */
    var MissionType = {
        TradeAssurance_Order: 'TradeAssurance_Order',
        Product_Publish: 'Product_Publish',
        Store_V1: 'Store_V1',
        Rfq_Offer: 'Rfq_Offer',
        Inq_Offer: 'Inq_Offer',
        SiteManage_Company: 'SiteManage_Company',
        P4P_KeywordBid: 'P4P_KeywordBid',
        Store_V2: 'Store_V2',
        SiteManage_SubAccount: 'SiteManage_SubAccount',
        SiteManage_QuickEntry: 'SiteManage_QuickEntry',
        Product_Manage: 'Product_Manage',
        Product_Window: 'Product_Window',
        ZeroEffectProduct: 'ZeroEffectProduct',
        P4P_AccountBudget: 'P4P_AccountBudget',
        Inq_Rubbish: 'Inq_Rubbish',
        Inq_Allot: 'Inq_Allot',
        Inq_CusGroup: 'Inq_CusGroup',
        Inq_CusAllot: 'Inq_CusAllot',
        Inq_AddCus: 'Inq_AddCus',
        Inq_Rule: 'Inq_Rule',
        Rfq_CustomSearch: 'Rfq_CustomSearch',
        Open_Alibaba_Fully: 'Open_Alibaba_Fully',
        MultiLangMarket_Product_Publish:'MultiLangMarket_Product_Publish',
        Inq_AddCus_From_TradeManage: 'Inq_AddCus_From_TradeManage',
        Product_Publish_DirectOrder: 'Product_Publish_DirectOrder',
        Business_AddCategory: 'Business_AddCategory',
        Business_AddTag: 'Business_AddTag',
        Inq_Send: 'Inq_Send',
        Rfq_Purchase: 'Rfq_Purchase',
        Order_Shipped_Offline: 'Order_Shipped_Offline',
    };

    var MissionType2Id = {
        TradeAssurance_Order: 'credit_order',
        Product_Publish: 'product_release',
        Store_V1: 'shop_decoration_1',
        Rfq_Offer: 'rfq_quotation',
        Inq_Offer: 'inquiry_reply',
        SiteManage_Company: 'company_infomation',
        P4P_KeywordBid: 'p4p_bid',
        Store_V2: 'shop_decoration_2',
        SiteManage_SubAccount: 'add_subaccount',
        SiteManage_QuickEntry: 'quick_entry',
        Product_Manage: 'product_manage',
        Product_Window: 'window_product',
        ZeroEffectProduct: 'zero_effect_product',
        P4P_AccountBudget: 'account_budget',
        Inq_Rubbish: 'junk_inquiry',
        Inq_Allot: 'inquiry_allocation',
        Inq_CusGroup: 'customer_group',
        Inq_CusAllot: 'customer_allocation',
        Inq_AddCus: 'customer_add',
        Inq_Rule: 'inquiry_allocation_rule',
        Rfq_CustomSearch: 'custom_search',
        Open_Alibaba_Fully: 'fully_open_alibaba',
        MultiLangMarket_Product_Publish: 'multi_lang_product_release',
        Inq_AddCus_From_TradeManage: 'customer_from_trademanage',
        Product_Publish_DirectOrder: 'product_post_direct_order',
        Business_AddCategory: 'business_subscription_add_category',
        Business_AddTag: 'business_subscription_add_tag',
        Inq_Send: 'send_inquiry',
        Rfq_Purchase: 'send_rfq_purchase',
        Order_Shipped_Offline: 'order_shipped_offline',
    };

    var commonTplScope = {
        asteriskSpan: '<span style="color: #cc1414">*</span>',
    };

    var askSyncMoreTaskTipTopTpl = [
        '<p style="color: #333;font-size: 14px;margin: 40px 40px 20px;line-height: 18px;font-weight: 700;">您即将完成 ${task} 任务，请从以下列表中选择您要同步的评测。</p>',
    ].join('');

    var submitPromptText = [
        '答案链接将同步到试卷作答区，请自行判断是否已完成作答，若继续作答，系统将自动同步更新至作答链接，无需重新提交',
    ].join('')

    var askSyncMoreTaskTipBottomTpl = [
        '<p style="color: #949494;font-size: 12px;margin: 10px 40px;line-height: 1.6em;">${asteriskSpan} ' +
        submitPromptText +
        '</p>',
        '<p style="color: #949494;font-size: 12px;margin: 10px 40px;line-height: 1.6em;">${asteriskSpan} 选择否可继续完成实训，试卷作答区不会生成作答链接</p>',
    ].join('');

    var askSyncMoreTaskTpl = [
        '<div style="color: #333;font-size: 14px;line-height: 18px; margin-bottom: 6px;">',
        '<label style="display: table;"><div style="display: table-cell;"><input style="display: inline-block; vertical-align: middle;" type="radio" ${checked} id="" name="task" class="training-mission-task-radio"></div>',
        '<div><p style="margin: 0;"> ${exam} </p>',
        '<p style="margin: 0;font-size:12px; font-weight: 700;"> ${course} </p>',
        '<p style="font-size: 12px; color: #ccc; margin: 0;">(${time})</p></div></label>',
        '</div>'
    ].join('');

    var askSyncTextTpl = [
        '<p style="color: #333;font-size: 14px;margin: 40px 40px 20px;line-height: 18px;font-weight: 700;">您即将完成 ${task} 任务，选择“是”则答案自动同步到 《${course}》 中的 《${exam}》</p>',
        '<p style="color: #949494;font-size: 12px;margin: 10px 40px;line-height: 1.6em;">${asteriskSpan} ' +
        submitPromptText +
        '</p>',
        '<p style="color: #949494;font-size: 12px;margin: 10px 40px;line-height: 1.6em;">${asteriskSpan} 选择否可继续完成实训，试卷作答区不会生成作答链接</p>',
    ].join('');

    var answerSyncSucTipTpl = [
        '<p style="color:#666;font-size: 14px;margin: 40px 40px 20px; line-height: 18px;">答案已保存到《${course}》中的《${exam}》，请回到该试卷中继续作答</p>',
    ].join('');

    var syncTaskFailTip = [
        '<p style="padding: 0 20px; margin: 8px 0; font-size: 14px;">同步任务失败，请重试！ 点击“取消”将不会同步答案。</p>',
        '<p style="padding: 0 20px; margin: 8px 0; font-size: 14px;">code: ${code}</p>',
        '<p style="padding: 0 20px; margin: 8px 0; font-size: 14px;">msg: ${info}</p>'
    ].join('');

    var queryTaskFailTip = [
        '<p style="padding: 0 20px; margin: 8px 0; font-size: 14px;">同步任务失败，请重试！ 点击“取消”将不会同步答案。</p>',
        '<p style="padding: 0 20px; margin: 8px 0; font-size: 14px;">code: ${code}</p>',
        '<p style="padding: 0 20px; margin: 8px 0; font-size: 14px;">msg: ${info}</p>'
    ].join('');

    var API = {
        queryTask: function (params) {
            return DSSX.request.get('/usr/api/queryTask', params, true);
        },
        syncTask: function (params) {
            return DSSX.request.get('/usr/api/syncTask', params, true);
        }
    };

    initTemplate();

    function initTemplate() {
        if (SYSTEM_MODE === 'exam') {
            answerSyncSucTipTpl = [
                '<p style="color:#666;font-size: 14px;margin: 40px 40px 20px; line-height: 18px;">答案已保存，请回到该试卷中继续作答</p>',
            ].join('');

            askSyncMoreTaskTpl = [
                '<div style="color: #333;font-size: 14px;line-height: 18px; margin-bottom: 6px;">',
                '<label style="display: table;"><div style="display: table-cell;"><input style="display: inline-block; vertical-align: middle;" type="radio" ${checked} id="" name="task" class="training-mission-task-radio"></div>',
                '<div>',
                '<p style="font-size: 12px; color: #ccc; margin: 0;">(${time})</p></div></label>',
                '</div>'
            ].join('');

            askSyncTextTpl = [
                '<p style="color: #333;font-size: 14px;margin: 40px 40px 20px;line-height: 18px;font-weight: 700;">您即将完成 ${task} 任务，选择“是”则答案自动同步</p>',
                '<p style="color: #949494;font-size: 12px;margin: 10px 40px;line-height: 1.6em;">${asteriskSpan} ' +
                submitPromptText  +
                '</p>',
                '<p style="color: #949494;font-size: 12px;margin: 10px 40px;line-height: 1.6em;">${asteriskSpan} 选择否可继续完成实训，试卷作答区不会生成作答链接</p>',
            ].join('');
        }

        if (SYSTEM_MODE === 'teach') {

        }
    }

    /**
     * 尝试启动实训任务
     * 查询任务、同步任务成功，或取消查询、取消同步 都进成功回调
     * @param type
     * @param {object=} option. {bindId, bindInfo, skip, force}
     *      bindInfo - 实训任务绑定的 数据
     *      bindId - 实训任务绑定的 id
     *      skip {boolean} - 是否跳过实训任务流程，设置为 true ，将忽略实训任务的流程（效果如同没有任务要做）
     *                      用于后续逻辑处理基本相同，但不需要提交实训任务时，保持后续逻辑统计、复用
     */
    function tryStart(type, option) {
        option = option || {};

        var
            pending = this.pending,
            last = pending[type],
            isSkip = !!option.skip,
            firstStep = $.Deferred() // 检查是否存在待做任务，若存在询问是否同步答案至评测
        ;

        if (isSkip) {
            // 直接跳过任务流程
            return firstStep.resolve('success');
        }

        if (last) {
            // 拒绝掉同一类型重复进入
            return firstStep.reject('error');
        }

        /**
         * 1、查询任务
         * 2、suc => 3; fail => 提示重新查询
         * 2、Y => 提示  N => return 通知页面没有任务
         * 3、同步任务
         * 4、Suc => 提示成功 Error => 提示重试并显示错误信息
         */
        var
            dialog = getDialogIns(),
            loaderBlanker = dialog.load();
        ;

        return queryTasks(type, option, loaderBlanker);
    }

    function queryTasks(type, option, loaderBlanker) {
        var taskBindInfo = option.bindInfo;
        return _checkMissions(type, {bindId: option.bindId || ''})
            .then(callback, errCallback);

        function callback(tasks) {
            loaderBlanker();

            // 不存在正在做的任务  通知页面没有任务
            if (!tasks.length) {
                return 'success';
            }

            // 存在任务，询问是否同步答案
            return askIfSync(tasks).then(function (choice) {
                if (!choice) {
                    return 'success';
                }

                var otherInfo = {
                    tid: choice.id,
                    bindInfo: taskBindInfo
                };

                // 同步任务
                return trySyncTask(type, otherInfo, choice);
            });
        }

        function errCallback(err) {
            loaderBlanker();
            console.log('[TM] tryStart:_checkMissions', err);

            return openQueryTaskFailDialog(err)
                .then(suc);

            function suc(info) {
                if (info === 'cancel') {
                    return $.Deferred().resolve('cancel');
                }

                return queryTasks(type, option, loaderBlanker);
            }
        }
    }

    function _checkMissions(type, option) {
        return getTasksByType(type, option).then(_checkMissionOnSuc, _checkMissionOnErr);

        function _checkMissionOnSuc(res) {
            var data = res.data || {};
            var taskList = data.taskList || [];

            var ret = taskList.map(function (task) {
                return {
                    id: task.id,
                    course: task.courseTitle || '',
                    name: task.trainingTitle || '',
                    exam: task.examTitle || '',
                    startTime: task.startTime,
                    startTimeStr: DSSX.util.formatDate(task.startTime, 'yyyy-MM-dd hh:mm:ss'),
                };
            });
            return ret;
        }

        function _checkMissionOnErr(err) {
            console.log('[TM] _checkMissionOnErr', err)
            return $.Deferred().reject(err);
        }
    }

    function getTasksByType(type, option) {
        var id = getMissionTypeId(type);
        if (typeof id === 'undefined') {
            return $.Deferred().reject(new Error('[TrainingMission] getTasksByType: Unsupported type:' + type));
        }
        option || (option = {});
        return API.queryTask({ttKey: id, bindId: option.bindId});
    }

    // 询问是否同步
    function askIfSync(tasks) {
        var content = '';
        var d = $.Deferred();
        var moreTaskTip = DSSX.util.compileTemplateLiterals(askSyncMoreTaskTipTopTpl, {task: tasks[0].name || '--'});
        var task;
        var btns, area;

        if (tasks.length === 1) {
            task = tasks[0];
            var scope = {
                task: task.name || '--',
                exam: task.exam || '--',
                time: task.startTimeStr || '--',
                course: task.course || '--'
            };
            Object.assign(scope, commonTplScope);

            content = DSSX.util.compileTemplateLiterals(askSyncTextTpl, scope);
            btns = ['是', '否'];
            area = ['455px', '350px'];
        } else {
            for (var i = 0; i < tasks.length; i++) {
                var scope1 = {
                    task: tasks[i].name || '--',
                    exam: tasks[i].exam || '--',
                    time: tasks[i].startTimeStr || '--',
                    course: tasks[i].course || '--',
                    checked: i === 0 ? 'checked' : '',
                };
                Object.assign(scope1, commonTplScope);
                var content1 = DSSX.util.compileTemplateLiterals(askSyncMoreTaskTpl, scope1);

                content += content1;
            }
            content = moreTaskTip + '<div style="margin: 0 40px 20px; overflow: auto; max-height: 147px;">' + content + '</div>'
              + DSSX.util.compileTemplateLiterals(askSyncMoreTaskTipBottomTpl, commonTplScope)
            ;
            btns = ['确定', '取消'];
            area = ['455px', '420px'];
        }

        var idx = layer.open({
            type: 1,
            title: '温馨提示',
            area: area,
            btn: btns,
            btnAlign: 'c',
            resize: false,
            closeBtn: 0,
            content: content,
            skin: 'dssx-trainning-mission-layer-style',
            success: function () {
                $('.training-mission-task-radio').each(function (i, e) {
                    $(e).on('click', function () {
                        task = tasks[i];
                    })
                })
            },
            yes: function () {
                layer.close(idx);
                d.resolve(task);
            },
            btn2: function () {
                layer.close(idx);
                d.resolve(null);
            },
            cancel: function () {
                d.resolve(null);
            },
        });
        return d.promise();
    }

    function trySyncTask(type, otherInfo, task) {
        // 打开遮罩 loading
        var
            dialog = getDialogIns(),
            loaderBlanker = dialog.load();
        ;
        return syncTask(type, otherInfo, task)
            .then(answerSyncSuc, answerSyncErr)
            .then(syncSuc, syncErr)

        function answerSyncSuc(task) {
            var
                d = $.Deferred(),
                scope = {
                    exam: task.exam || '--',
                    course: task.course || '--'
                },
                content = DSSX.util.compileTemplateLiterals(answerSyncSucTipTpl, scope)
            ;

            var idx = layer.open({
                type: 1,
                title: '温馨提示',
                area: ['455px', '200px'],
                btn: '我知道了',
                btnAlign: 'c',
                resize: false,
                closeBtn: 0,
                content: content,
                yes: function () {
                    layer.close(idx);
                    d.resolve('success');
                },
                cancel: function () {
                    d.resolve('success');
                },
            });

            return d.promise();
        }

        function answerSyncErr(err) {
            console.log('[TM] tryStart:syncTask', err);
            return $.Deferred().reject(err);
        }

        function syncSuc(data) {
            loaderBlanker();
            return data;
        }

        function syncErr(err) {
            loaderBlanker();
            return openSyncTaskFailDialog(err)
                .then(suc);

            function suc(info) {
                if (info === 'cancel') {
                    return $.Deferred().resolve('cancel');
                }

                return trySyncTask(type, otherInfo, task);
            }
        }
    }

    // 同步任务
    function syncTask(type, option, task) {
        option = option || {};

        return syncTasksByType(type, option)
            .then(syncTasksOnSuc, syncTasksOnErr);

        function syncTasksOnSuc(res) {
            return task;
        }

        function syncTasksOnErr(err) {
            console.log('[TM] syncTask', err)
            return $.Deferred().reject(err);
        }
    }

    function syncTasksByType(type, option) {
        var id = getMissionTypeId(type);
        if (typeof id === 'undefined') {
            return $.Deferred().reject(new Error('[TrainingMission] syncTasksByType: Unsupported type:' + type));
        }
        option || (option = {});
        return API.syncTask({ttKey: id, tid: option.tid, bindInfo: option.bindInfo});
    }

    // 打开同步任务失败提示
    function openSyncTaskFailDialog(error) {
        var d = $.Deferred();
        var scope = {
            code: DSSX.util.safe(error, 'data.data.code', -1),
            info: DSSX.util.safe(error, 'data.data.msg', '')
        };
        var content = DSSX.util.compileTemplateLiterals(syncTaskFailTip, scope);
        layer.open({
            type: 1,
            title: '温馨提示',
            area: ['455px', '200px'],
            btn: ['重试', '取消'],
            btnAlign: 'c',
            resize: false,
            closeBtn: 0,
            content: content,
            skin: 'dssx-trainning-mission-layer-style',
            yes: function (index) {
                layer.close(index);
                d.resolve('retry');
            },
            btn2: function (index) {
                layer.close(index);
                d.resolve('cancel');
            },
        });
        return d.promise();
    }


    // 打开查询任务失败提示
    function openQueryTaskFailDialog(error) {
        var d = $.Deferred();
        var scope = {
            code: DSSX.util.safe(error, 'data.data.code', -1),
            info: DSSX.util.safe(error, 'data.data.msg', '')
        };
        var content = DSSX.util.compileTemplateLiterals(queryTaskFailTip, scope);
        layer.open({
            type: 1,
            title: '温馨提示',
            area: ['455px', '200px'],
            btn: ['重试', '取消'],
            btnAlign: 'c',
            resize: false,
            closeBtn: 0,
            content: content,
            skin: 'dssx-trainning-mission-layer-style',
            yes: function (index) {
                layer.close(index);
                d.resolve('retry');
            },
            btn2: function (index) {
                layer.close(index);
                d.resolve('cancel');
            },
        });
        return d.promise();
    }

    /**
     * get dialog instance
     */
    function getDialogIns() {
        var dialog = window.layer,
            loadIdx;

        return {
            load: load,
            closeLoad: closeLoad,
        };

        function load() {
            loadIdx = dialog.load(3, {shade: 0.2});
            return closeLoad;
        }

        function closeLoad() {
            if ('undefined' !== typeof loadIdx) {
                dialog.close(loadIdx);
                loadIdx = undefined;
            }
        }

    }

    // ------------ ------------
    function getMissionTypeId(type) {
        return MissionType2Id[type];
    }


    /**
     * @typedef {object} TrainingMission
     * @property {T_MissionType} MissionType
     * @property {function} tryStart
     * @property {function} getMissionTypeId
     */
    DSSX.TrainingMission = {
        MissionType: MissionType,
        tryStart: tryStart,
        getMissionTypeId: getMissionTypeId,
        pending: {},
    };

}(window));
