/**
 * Request Adaptor
 * 在兼容 v1-www 旧的请求帮助函数接口情况下，桥接到新工程的公共请求方法
 */

;(function (window) {
    var DSSX = window.DSSX;


    /**
     * BaseAdapter
     * @constructor
     */
    var BaseAdapter = (function () {
        function BaseAdapter() {
            this.url = '';
            this.json = ''; // string - post 请求发送的数据 (旧的 PostHandler)
            this.params = null; // object - 附在 url 的参数 (Adapter 新加)
            this.data = null; // object - post 请求发送的数据，优先使用这个 (Adapter 新加)
            this.options = null; // object - jq ajax options (Adapter 新加)
        }

        BaseAdapter.version = '2.0';

        // start request
        BaseAdapter.prototype.start = function () {
            return this.createHttp(this).then(this.requestSuccess.bind(this), this.requestFailure.bind(this));
        }

        // request success
        BaseAdapter.prototype.requestSuccess = function (response) {
            if (this.onSuccess) {
                try {
                    this.onSuccess(this.dataTransport(response));
                } catch (e) {
                    console.error('[requestAdapter] onSuccess Uncaught exception:', e);
                }
            }
            return response;
        }

        // request failure
        BaseAdapter.prototype.requestFailure = function (err) {
            if (this.onError) {
                try {
                    this.onError(err.data, '', err);
                } catch (e) {
                    console.error('[requestAdapter] onError Uncaught exception:', e);
                }
            }
            return $.Deferred().reject(err);
        }

        BaseAdapter.prototype.createHttp = function (config) {
            return $.ajax(config.url, config.options);
        }

        // 转换调用 onSuccess 传的数据
        BaseAdapter.prototype.dataTransport = function (data) {
            return data;
        }

        /**
         * onSuccess callback
         * @desc 兼容旧的接口
         * @param {object} data 后台返回的数据(res)中的 data字段
         */
        BaseAdapter.prototype.onSuccess = null;

        /**
         * onError callback
         * @desc 兼容旧的接口
         * @param {object} response 后台返回的数据
         * @param {string} msg
         * @param {object} err 公共请求方法返回的错误对象
         */
        BaseAdapter.prototype.onError = null;

        return BaseAdapter;
    })();


    /**
     * requestAdapter
     * @callback createHttp
     */
    function requestAdapter(createHttp) {
        if (typeof createHttp !== 'function') {
            throw new TypeError('The createHttp must be a function');
        }

        function Adapter() {
            BaseAdapter.call(this);
        }

        DSSX.util.inherits(Adapter, BaseAdapter);
        Adapter.prototype.createHttp = createHttp;
        return Adapter;
    }

    // 默认 OnError
    function defaultOnError(response, msg, err) {
        if (DSSX.dialog) {
            DSSX.dialog.err(err, {
                /*funcName: this.url,*/
            });
        } else if (window.layer) {
            layer.msg("操作失败，错误码：" + response.code);
        }
    }

    function installation() {
        var request = DSSX.request;
        if (!request) {
            return;
        }

        if (window.GetHandler) {
            window.GetHandler = requestAdapter(function (config) {
                return request.get(config.url, config.params, true, config.options);
            });
            window.GetHandler.prototype.onError = defaultOnError;
            window.GetHandler.prototype.dataTransport = function (response) {
                return response && response.data;
            }

            console.info('GetHandler RequestAdaptor has been installed');
        }

        if (window.PostHandler) {
            window.PostHandler = requestAdapter(function (config) {
                return request.post(config.url, config.params, config.data || config.json, true, config.options);
            });
            window.PostHandler.prototype.onError = defaultOnError;

            console.info('PostHandler RequestAdaptor has been installed');
        }
    }

    DSSX.loadHandler.New().onload = installation;
}(window));