!function () {
    DSSX.loadHandler.New().onload = initHttpFilter;

    function initHttpFilter() {
        $(document).ajaxSuccess(function (ev, rs, set) {
            var code = DSSX.util.safe(rs, 'responseJSON.code', 0);
            // console.log(rs, code);
            if (code === 403 || code === 413) {
                (DSSX.godMode && DSSX.godMode.msg ? DSSX.godMode.msg :
                    alert)(code === 403 ? '预览模式只能查看，不能进行修改操作。' : '无权限访问');
            }
        });
    }
}();