/**
 * Created by zhengyf 2017/8/30
 */


/***
 * 预览模式下部分页面样式会乱，可在页面添加 .dssx-god-mode-config 在该class下编写合适的css
 */

!function () {
    var userInfo;
    var isTransparentMod = false;

    DSSX.loadHandler.New().onload = init;

    /**
    * 判断是否需要显示提示,新旧项目嵌套需要
    * @return {boolean}
    */
    window.switchIframeMode = function(bool){
        isTransparentMod = bool
        if(isTransparentMod){
            // 移除DOM
            var godModeHtml = document.getElementsByClassName('god-mode-tip');
            godModeHtml[0] && godModeHtml[0].parentElement.removeChild(godModeHtml[0])
            $('body').removeClass('god-mode-padding-top-fix dssx-god-mode-config');
        }else{
            init()
        }
    }


    function init() {
        console.log('[god mode] init:');
        userInfo = getUserInfo();
        godModeView();
        initGodModeCtrl();
    }

    function godModeView() {
        // 1、判断是否是上帝模式 （判断域名前缀）
        // 2、判断是否是老师

        var viewMode = isGodMode(); // 是否是上帝模式
        if (viewMode) {
            // 是否自己访问自己
            if (isSelf()) {
                createTipDom('自身');
                return;
            }

            // 是否存在该学生
            if (!(window.DSSX.stuInfo && window.DSSX.stuInfo.uid)) { // 不存在该学生
                noStudent();
                return;
            }

            var pInfo = getPinfo();
            userInfo.isTeacher ? teacherPreview(pInfo.visitedUserName) : studentPreview();
        }
    }

    /**
     * 判断是否是上帝模式 dsalbbp50.dev.gdy.io
     * @return {boolean}
     */
    function isGodMode() {
        return DSSX.util.safe(DSSX, 'attr.isPreviewMode', false);
        // // dsalbbp50.dev
        // var hostFirstStr = window.location.host.slice(0, 7);
        // return hostFirstStr === 'dsalbbp';
    }

    /**
     * 获得访问信息
     * @return {object}
     */
    function getUserInfo() {
        var uInfo = window.DSSX.uinfo || {};
        uInfo.isTeacher = uInfo.isTeacher || false;
        return {
            isTeacher: uInfo.isTeacher
        };
    }

    /**
     * pinfo
     */
    function getPinfo() {
        return {
            visitedUserName: DSSX.util.safe(DSSX.stuInfo, 'usr.attrs.basic.nickName', '')
        };
    }

    /**
     * 老师预览学生国际站
     * @params {string} userName 被访问者的用户名
     */
    function teacherPreview(userName) {
        var teacherName = DSSX.uinfo.usr.attrs.basic.nickName || '';
        userName = userName || '';

        createTipDom('【' + userName + '】', teacherName);
        var reg = /[/][a-zA-Z-]+[\.][h][t][m][l]/g;
        var page = window.location.href.match(reg) || []; // 获得当前访问的页面
        if (!page[0]) { // page 可能 [];
            return;
        }
        var result = isPurviewVisit(page[0]);
        if (!result) { // 无权访问
            var index = layer.open({
                closeBtn: 0,
                title: '提示',
                content: '您当前是预览模式，无权访问该页面！',
                btn: ['确定'],
                yes: function () {
                    window.location.href = '/';
                    layer.close(index);
                }
            });
        }
    }

    /**
     * 学生预览学生国际站
     */
    function studentPreview() {
        var index = layer.open({
            closeBtn: 0,
            title: '提示',
            content: '您无权访问！点击确定，回到您的国际站！',
            btn: ['确定'],
            yes: function () {
                if(DSSX.APP_CONFIG.dev.previewMethod && DSSX.APP_CONFIG.dev.previewMethod == 'cookie'){
                    window.location.href = DSSX.host.protocol + '//' + DSSX.host.main+ '/pub/api/back2MyAlibaba';
                }else{
                    window.location.href = DSSX.host.protocol + '//' + DSSX.host.main;
                }
                layer.close(index);
            }
        });
    }

    /**
     * 学生不存在
     */
    function noStudent() {
        var index = layer.open({
            closeBtn: 0,
            title: '提示',
            content: '您查看的用户不存在，点击确定，回到您的国际站！',
            btn: ['确定'],
            yes: function () {
                window.location.href = DSSX.host.protocol + '//' + DSSX.host.main;
                layer.close(index);
            }
        });
    }

    /**
     * 创建提示dom
     */
    function createTipDom(userName, teacher) {
        userName = userName || '';
        var body = document.getElementsByTagName('body')[0];
        var godModeHtml = document.createElement('div');
        godModeHtml.className = 'god-mode-tip';
        godModeHtml.innerHTML = '<span class="god-mode-tip-info"></span>';
        $('body').addClass('god-mode-padding-top-fix dssx-god-mode-config');
        body.appendChild(godModeHtml);
        var infoDom = document.getElementsByClassName('god-mode-tip-info')[0];

        if (teacher) {
            infoDom.innerHTML = '【预览模式】' + teacher + '老师，您正在查看' + userName + '的国际站' + '<a class="goto-myself">返回我的国际站</a>';
        } else {
            infoDom.innerHTML = '【预览模式】您正在查看' + userName + '的国际站' + '<a class="goto-myself">返回我的国际站</a>';
        }

        $('.god-mode-tip .goto-myself').on('click', function () {
            if(DSSX.APP_CONFIG.dev.previewMethod && DSSX.APP_CONFIG.dev.previewMethod == 'cookie'){
                location.href = DSSX.host.protocol + '//' + DSSX.host.main + '/pub/api/back2MyAlibaba'
            }else{
                location.href = DSSX.host.protocol + '//' + DSSX.host.main;
            }

        })
    }


    /**
     * 判断是否有权访问
     * @params {string} pageName 访问的页面对应的html文件名
     * @return {boolean}
     */
    function isPurviewVisit(pageName) {
        pageName = pageName || '';
        var unVisitPage = [
            // '/productLaunch.html',
            // '/window-product-manage.html',
            // '/shop-design.html',
            // '/sub-account-add.html',
            // '/sub-account-manage.html',
            // '/safe-problem-setting.html',
            // '/post-auth-info.html',
            // '/tmpl-edit.html',
            // '/exam.html',
            // '/aliSource.html',
            // '/shippingOrderDetail.html',
            // '/trusts.html',
            // '/account-center.html'
        ];
        var result = true;
        for (var i = 0, len = unVisitPage.length; i < len; i += 1) {
            if (pageName === unVisitPage[i]) {
                result = false;
                break;
            }
        }
        return result;
    }

    /**
     * 判断是否是自己访问自己
     * @returns {boolean}
     */
    function isSelf() {
        var uInfo = window.DSSX.uinfo || {};
        uInfo.uid = uInfo.uid || '';
        var stuInfo = window.DSSX.stuInfo || {};
        stuInfo.uid = stuInfo.uid || '';

        return uInfo.uid === stuInfo.uid;
    }


    function initGodModeCtrl() {
        DSSX.godMode = {
            msg: godModeMsg,
        };
    }

    // 预览模式显示消息
    // 从 http filter 迁移过来的，统一控制
    function godModeMsg(arg_msg) {
        if(isTransparentMod){
            parent && (parent.iframePreviewWarnTip(arg_msg))
            return 
        }
        var cur = $('.forbidden-tip'),
            bas = arg_msg + '<i class="iconfont icon-close" onclick="$(this).parent().remove()"></i>';
        if (cur.length) {
            cur.html(bas);
        } else {
            var content = '<div class="forbidden-tip god-mode-forbidden-tip">' + bas + '</div>';
            $('body').append(content).addClass('god-mode-padding-top-fix dssx-god-mode-config');
            setTimeout(function () {
                $('.god-mode-forbidden-tip').remove()
            }, 2000);
        }
        setTimeout(function () {
            $('html,body').stop().animate({scrollTop: 0}, 'fast');
            // layer.closeAll();
        });
        // setTimeout(function () {
        //     layer.closeAll();
        // }, 1000);
    }

}();
